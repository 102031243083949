<template>
  <div class="modal-text-content" style="text-align: left">
    <nav 
        >
        <div class="nav-container">
            <img class="logoRepower" @click="backHome"
                src="~@/assets/img/repower-logo.svg"  alt="Repower"/>

                <img class="logoRCN"  @click="backHome"
                src="~@/assets/img/rcn-logo.svg" alt="Repower charging net"/>
        </div>
    </nav>

          <h3 id="titleCookie" class="inner-title"><span class="red" style="font-weight: 100;">Informativa sui cookie</span></h3>
          <div class="small-text standard-text">
              <p>
                  La presente Cookie Policy costituisce integrazione dell’informativa privacy, predisposta ai sensi dell’art. 13 del Reg. UE n. 679/2016 (d’ora in avanti “GDPR”) con riferimento al trattamento dei Suoi dati personali, consultabile a <a style="text-decoration: underline;" class="link" @click="privacyHandler">questo link</a>.<br />
                  Tanto premesso, la società Repower Vendita Italia S.p.A. (di seguito anche solo “Società” o “Repower”), con sede legale in 20129 Milano in via Uberti 37, P.I. 13181080154, in persona del legale rappresentante p.t., in qualità di titolare del trattamento dei dati personali degli utenti del sito internet <b>chargingnet.repower.com</b> (di seguito anche solo “Sito”), rende noto che il predetto Sito utilizza cookie, al fine di rendere l’esperienza di navigazione piacevole ed efficiente.
              </p>
              <p>
                  <strong>1. Definizione di cookie</strong>
                  <br />
                  <br />
                  I <strong>cookie</strong> sono piccoli file di testo inviati dal Sito al terminale dell’utente (solitamente al browser), in cui sono memorizzati per essere ritrasmessi al Sito alla successiva visita del medesimo utente. I cookie sono infatti utili perché consentono ad un sito web di riconoscere il dispositivo dell’utente. Di seguito sono fornite maggiori informazioni circa i cookie utilizzati e sulle modalità di gestione delle impostazioni relative ad essi.
              </p>
              <div>
                  <strong>2. Tipologie di cookie utilizzati</strong>
                  <br />
                  <br />
                  Si riportano qui di seguito le possibili categorie di cookie utilizzate all’interno del Sito. Più in particolare:
                  <ul class="padding-0">
                      <li>
                          <p>Nell’ambito della gestione del sito, possono essere individuati:</p>
                          <ul class="decorated-list">
                              <li>
                                  <p><strong>Cookie persistenti</strong>: questi cookie rimangono memorizzati, fino alla loro scadenza, sul dispositivo dell’utente. Sono usati al fine di agevolare la navigazione all’interno del Sito e la sua corretta fruizione, per facilitare l’accesso ai servizi che richiedono l’autenticazione (evitando che gli utenti debbano reinserire le credenziali di autenticazione ad ogni accesso ai servizi), a fini statistici, per conoscere quali aree del sito sono state visitate, nonché, in alcuni casi, per una gestione ottimale delle pagine e degli spazi pubblicitari, ovvero per erogare contenuti e pubblicità in linea con le scelte operate dagli utenti.</p>
                              </li>
                              <li>
                                  <p><strong>Cookie di sessione</strong>: <u>questi cookie non vengono memorizzati in modo persistente sul dispositivo dell’utente e svaniscono con la chiusura del browser</u>. Sono utilizzati al fine di trasmettere gli identificativi di sessione necessari per consentire l’esplorazione sicura ed efficiente del Sito.</p>
                              </li>
                          </ul>
                          <br />
                      </li>
                      <li>
                          <p>A seconda della provenienza dei cookie, inoltre, possono essere utilizzati:</p>
                          <ul class="decorated-list">
                              <li>
                                  <p><strong>Cookie di prima parte</strong>: <u>questi cookie sono gestiti direttamente da Repower</u>. Possono essere utilizzati, ad esempio, per garantire il funzionamento tecnico del Sito o per tenere traccia di preferenze espresse in merito all’uso del Sito stesso. Possono essere sia persistenti che di sessione;</p>
                              </li>
                              <li>
                                  <p><strong>Cookie di terza parte</strong>: <u>questi cookie sono generati e gestiti da “soggetti” esterni al Sito visitato dall’utente (non sono quindi “di proprietà” di Repower)</u>. Tali cookie sono utilizzati, ad esempio, per conoscere il numero di pagine visitate all’interno del sito stesso (i cosiddetti “cookie statistici” o “cookie analytics”), oppure per pubblicare sullo stesso contenuti o pubblicità, anch’essi possono essere sia persistenti sia di sessione.</p>
                              </li>
                          </ul>
                          <br />
                      </li>
                      <li>
                          <p>In funzione delle finalità di utilizzazione dei cookie, infine, possiamo distinguerli in:</p>
                          <ul class="decorated-list">
                              <li>
                                  <p>
                                      <strong>Cookie tecnici</strong>: <u>tali cookie servono per la navigazione dell’utente sul Sito, oltre che per consentirgli la fruizione di particolari servizi eventualmente richiesti</u>. Laddove strettamente necessari, non sarà possibile la loro disinstallazione da parte dell’utente.<br />L’uso di cookie tecnici è consentito al fine di “<i>effettuare la trasmissione di una comunicazione su una rete di comunicazione elettronica, o nella misura strettamente necessaria al fornitore di un servizio della società dell’informazione esplicitamente richiesto dal contraente o dall’utente a erogare tale servizio</i>” (cfr. art. 122, co. 1, D.Lgs. 196/2003).
                                      <br />
                                      <br />
                                      I “cookie analytics” rientrano nell’ambito dei cookie tecnici solo se utilizzati per analizzare statisticamente gli accessi o le visite al sito in forma aggregata, senza cioè la possibilità di risalire all’identificazione del singolo utente.
                                      <br /> 
                                      Per l’installazione dei predetti cookie non è richiesto il preventivo consenso dell’utente.
                                      <br />
                                      Si precisa che i richiamati cookie tecnici verranno utilizzati esclusivamente per le finalità di seguito riportate:
                                  </p>
                                  <ol class="ol-basic" style="list-style-type: lower-alpha;">
                                      <li>navigazione e fruizione del sito web;</li>
                                      <li>raccolta di informazioni, in forma aggregata, sul numero degli utenti e su come questi visitano il sito stesso;</li>
                                      <li>navigazione in funzione di una serie di criteri selezionati.</li>
                                  </ol>
                              </li>
                              <li>
                                  <p>
                                      <strong>Cookie di profilazione</strong>:
                                      <u>i cookie di profilazione utilizzati all’interno del Sito servono a tracciare la navigazione dell’utente e sono - ad esempio - utilizzati al fine di inviare messaggi pubblicitari in linea con le preferenze da quest’ultimo manifestate.</u>
                                      <br /><br />
                                      L’utilizzo dei predetti cookie necessita l’acquisizione preventiva del consenso, che l’utente può decidere di prestare o meno. 
                                  </p>
                              </li>
                          </ul>
                          <br />
                      </li>
                  </ul>
                  <br /><br />
                  <div class="cky-audit-table-element"></div>
              </div>
              <p>
                  <strong>3. Come disattivare i cookie dal browser</strong><br /><br />La maggior parte dei browser consente di gestire i cookie, accettandoli o rifiutandoli tutti, ovvero solo alcuni tipi. La procedura per la gestione e l’eliminazione dei cookie si trova nella guida in linea integrata nel vostro browser. Inoltre, l’utente potrà comunque impostare il browser in maniera da rifiutare automaticamente la ricezione dei cookie, intervenendo quindi sulle relative opzioni.<br /><br />In ogni caso, sarà possibile in qualsiasi momento modificare le scelte relative alla ricezione dei cookie e/o disabilitare gli stessi, seguendo le indicazioni fornite dai produttori dei principali browser:
                  <ul style="text-decoration: underline;" class="decorated-list">
                      <li>
                          <a class="link" href="https://support.google.com/chrome/answer/95647?hl=it" target="_blank">Google Chrome</a>
                      </li>
                      <li>
                          <a  class="link" href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie" target="_blank">Mozilla Firefox</a>
                      </li>
                      <li>
                          <p><a class="link" href="https://support.microsoft.com/it-it/help/4468242/microsoft-edge-browsing-data-and-privacy" target="_blank">Microsoft Edge</a></p>
                      </li>
                      <li>
                      <a class="link" href="http://windows.microsoft.com/it-it/internet-explorer/delete-manage-cookies#ie=ie-11" target="_blank">Microsoft Internet Explorer</a>
                      </li>
                      <li>
                          <a class="link" href="https://support.apple.com/kb/ph21411?locale=it_IT" target="_blank">Apple Safari per macOS</a>
                      </li>
                      <li>
                          <a class="link" href="https://support.apple.com/it-it/HT201265" target="_blank">Apple Safari per iOS</a>
                      </li>
                      <li>
                          <a class="link" href="https://www.opera.com/help/tutorials/security/privacy/" target="_blank">Opera</a>
                      </li>
                  </ul>
              </p>
          </div>
      </div>
</template>
  
  <script>

  export default {
    name: "CookiePage",
    mounted: function(){
        if(typeof globalcookie !== 'undefined'){
            // eslint-disable-next-line
            globalcookie.updateCookieUI()
        }    
    },
    methods: {
        privacyHandler: function(){
            this.$router.push('/privacy');
        },
        backHome: function(){
            this.$router.push('/');
        }
    }
  };
  </script>
  <style scoped>
      @import '@/style/components/_cookie.scss';

  </style>
